<template>
  <div class="PageBox">
    <van-nav-bar
            fixed
            :border="false"
            :title="$t('fundRecord.default[2]')"
            left-arrow
            @click-left="$router.go(-1)"
    />
    <div class="ScrollBox">
      <div class="tool">
        <table style="text-align: center;width: 95%">
          <tr><td> {{ $t("hall.default[1]") }}(USDT)</td></tr>
          <tr style="font-size: 18px"><td>
            {{ Number(UserInfo.balance).toFixed(2) }}
          </td></tr>
          <tr><td>
            ≈
          </td></tr>
          <tr style="font-size: 18px"><td>
            {{Number(UserInfo.balance*InitData.usdtinfo.price).toFixed(2) }} {{InitData.usdtinfo.sign}}
          </td></tr>
        </table>
      </div>
      <van-tabs color="#F0B80D" title-active-color="#F0B80D" title-inactive-color="#ccc" :ellipsis="false"
                :border="false" v-model="tabsIndex" @change="changeTabs">
        <van-tab style="padding: 0 0px;width: 135px;margin-left: -25px">
          <template #title>
            <div :class="{'activeTab':tabsIndex==0}">
              <p style="font-size: 15px">{{$t('home4[3]')}}</p>
            </div>
          </template>
        </van-tab>
        <van-tab style="padding: 0 0px;">
          <template #title>
            <div :class="{'activeTab':tabsIndex==1}">
              <p style="font-size: 16px">{{$t('wallet.default[1]')}}</p>
            </div>
          </template>
        </van-tab>
        <van-tab style="padding: 0 0px;">
          <template #title>
            <div :class="{'activeTab':tabsIndex==2}">
              <p style="font-size: 16px">{{$t('wallet.default[2]')}}</p>
            </div>
          </template>
        </van-tab>
      </van-tabs>
      <van-pull-refresh v-model="isRefresh" @refresh="onRefresh">
        <van-list
          v-model="isLoad"
          :finished="isFinished"
          :finished-text="
            listData[tabsIndex].length ? $t('vanPull[0]') : $t('vanPull[1]')
          "
          @load="onLoad"
          :class="{ Empty: !listData[tabsIndex].length }"
        >
          <div v-if="tabsIndex == 0">
            <van-cell
              class="FundItem"
              v-for="(item, index) in listData[tabsIndex]"
              :key="item.dan"
            >
              <div :class="`icon tag${tabsIndex}`" slot="icon"></div>
              <template #title>
                <div>
                  <span class="dan" style="color:#cccccc">
                    {{$t('hall.list[0]')}}:  {{ item.amount }} USDT
                  </span>
                  <span>{{ item.adddate }}</span>
                </div>
                <div>
                  <span :style="item.task_type==2?'color:#F0B80D':'color:#0FCB81'">{{item.task_typestr}} USDT</span>
                  <span>{{ item.statestr}}</span>
                </div>
              </template>
            </van-cell>
          </div>
          <div v-else>
            <van-cell
              class="FundItem"
              v-for="(item, index) in listData[tabsIndex]"
              :key="item.order_id"
            >
              <div :class="`icon tag${tabsIndex}`" slot="icon"></div>
              <template #title>
                <div>
                  <span class="dan" style="color:#cccccc">{{ item.order_number }}</span>
                  <span>{{ item.adddate }}</span>
                </div>
                <div>
                  <span class="money" v-if="tabsIndex==1">{{ item.jj }}{{ parseFloat(item.money).toFixed(2) }} USDT</span>
                  <span class="money" v-if="tabsIndex==2">{{ item.jj }}{{ parseFloat(item.money).toFixed(2)  }} {{InitData.usdtinfo.sign}}</span>
                  <span v-if="tabsIndex==1">{{ item.statestr}}</span>
                  <span v-if="tabsIndex==2&&item.status==1">{{$t('wallet.default[2]')}}{{$t('common3[0]')}}</span>
                  <span v-if="tabsIndex==2&&item.status==2">{{$t('wallet.default[2]')}}{{$t('login.codes[3]')}}</span>
                  <span v-if="tabsIndex==2&&item.status==3">{{$t('task.msg[0]')}}</span>
                </div>
              </template>
            </van-cell>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
export default {
  name: "FundRecord",
  components: {},
  data() {
    return {
      listData: "",
      isLoad: false,
      isFinished: false,
      isRefresh: false,
      pageNo: 1,
      tabsIndex: 0,
      taskTabs: [
        { state: 0, text: this.$t("common7[4]") },
        { state: 1, text: this.$t("fundRecord.tabs[0]") },
        { state: 2, text: this.$t("fundRecord.tabs[1]") },
      ],
    };
  },
  computed: {},
  created() {

    this.listData = this.taskTabs.map((item) => "");
    this.$parent.navBarTitle = "";

    this.getListData("init");
    console.log(this.listData);
  },
  mounted() {},
  activated() {},
  destroyed() {},
  methods: {
    onLoad() {
      this.getListData("load");
    },
    changeTabs(index) {
      this.tabsIndex = index;
      this.getListData("init");
    },
    getListData(type) {
      this.isLoad = true;
      this.isRefresh = false;
      if (type == "load") {
        this.pageNo += 1;
      } else {
        this.pageNo = 1;
        this.isFinished = false;
      }
      //买卖
      if (this.tabsIndex == 0) {
        let param = {
          status: 3,
          page_no: this.pageNo,
          is_u: 2
        }
        this.$Model.GetTaskRecord(param,data => {
          this.isLoad = false;
          if (data.code == 1) {
            var items = data.info;
            items.forEach(item=>{
              var task_type = item['task_type']
              var ordertype = item['ordertype']
              var typestr = this.$t('hall4[6]')
              if (task_type==2||ordertype==2)
                typestr = this.$t('hall4[7]')
              item['task_typestr'] = typestr;
              var pay_state = item['pay_state']
              var statestr = this.$t('task3[1]')
              if (pay_state==2){
                statestr = this.$t('task3[2]')
              }
              item['statestr'] = statestr;
            })
            if (type == "load") {
              this.listData[this.tabsIndex] = this.listData[
                      this.tabsIndex
                      ].concat(items);
            } else {
              this.listData[this.tabsIndex] = items;
            }
            if (this.pageNo == data.data_total_page) {
              this.isFinished = true;
            } else {
              this.isFinished = false;
            }
          } else {
            this.listData[this.tabsIndex] = "";
            this.isFinished = true;
          }
          }
        );
      }else if (this.tabsIndex == 1) {
        this.$Model.GetRechargeRecord(
                { task_type: 2,is_u:1, page_no: this.pageNo },
                (data) => {
                  this.isLoad = false;
                  if (data.code == 1) {
                    if (type == "load") {
                      this.listData[this.tabsIndex] = this.listData[
                              this.tabsIndex
                              ].concat(data.info);
                    } else {
                      this.listData[this.tabsIndex] = data.info;
                    }
                    if (this.pageNo == data.data_total_page) {
                      this.isFinished = true;
                    } else {
                      this.isFinished = false;
                    }
                  } else {
                    this.listData[this.tabsIndex] = "";
                    this.isFinished = true;
                  }
                }
        );
      } else{
        this.$Model.GetDrawRecord(
          { page_no: this.pageNo },
          (data) => {
            this.isLoad = false;
            if (data.code == 1) {
              if (type == "load") {
                this.listData[this.tabsIndex] = this.listData[
                  this.tabsIndex
                ].concat(data.info);
              } else {
                this.listData[this.tabsIndex] = data.info;
              }
              if (this.pageNo == data.data_total_page) {
                this.isFinished = true;
              } else {
                this.isFinished = false;
              }
            } else {
              this.listData[this.tabsIndex] = "";
              this.isFinished = true;
            }
          }
        );
      }
    },
    onRefresh() {
      this.getListData("init");
    },
  },
};
</script>

<style scoped>
  .PageBox {
    color: #635327;
    background-color: #13171A;
  }

  .ScrollBox >>> .van-tabs__nav {
    background: #13171A;
  }
  .ScrollBox >>> .van-tab {
    height: 55px;
    background: #13171A;
    padding: 0;
  }

  .van-nav-bar>>>.van-nav-bar__title{
    color:#cccccc;
  }
  .van-nav-bar>>>.van-nav-bar__arrow {
    color:#B5B5B5;
  }

  .PageBox >>> .van-tabs__line {
    height: 0px;
  }


.van-pull-refresh {
  min-height: calc(100vh - 90px);
}
.PageBox>>>.tool::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: -80%;
}
.tool {
  /*background: url('../../../public/static/images/bg_wallet.png') no-repeat center 0;*/
  width: 95%;
  border-radius: 10px;
  /*background-color: rgba(244,211,79,0.4);*/
  color: #e7e7e7;
  background-color: #191C23;
  height: 120px;
  overflow: hidden;
  z-index: 99;
  padding: 0 5px;
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-top: 10px;
  padding: 20px;
}
.van-cell__title {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}

.van-cell__title div {
  display: flex;
  flex-direction: column;
}
.van-cell__title div:nth-child(2) {
  align-items: flex-end;
}

.FundItem{
  background-color: #191C23;
  margin-top: 5px;
  color:#aaaaaa;
}

.van-tab__pane {
  width: 98%;
}

.PageBox /deep/ .van-tab__text {
  width: 90px;
  color: #aaaaaa;
  text-align: center;
  font-size: 18px;
}
.PageBox /deep/ .van-tabs__wrap {
  border-radius: 10px;
  height: 60px;
}

  .PageBox /deep/ .van-tab :nth-of-type(1) div {
    background-color: #000;
    padding: 5px;
    padding-left: 0px;
    border: 1px solid;
    border-radius: 10px;
    color: #fff;
  }

.PageBox /deep/ .van-tab--active :nth-of-type(1) div {
  background-color: #F5C73A;
  padding: 5px;
  border-radius: 10px;
  color: #000;
}
.PageBox /deep/ .van-tab--active :nth-of-type(1) div div {
  background-color: #F5C73A;
  padding: 5px;
  border-radius: 10px;
  color: #000;
}
.myhead {
  position: fixed;
  top: 0px;
  font-size: 18px;
  color: #000;
  background: #fff;
  width: 100%;
  height: 46px;
  line-height: 46px;
  text-align: center;
}
</style>